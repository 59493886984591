import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Section from "@components/section"
import Seo from "@components/seo"
import HeroIH from "@components/heroIH"
import Container from "@components/container"
import BorderedContent from "@components/borderedContent"
import References from "@components/references"

// Assets
import chartIcon from "@images/icons/icon-ih-gradient-chart.svg"
import safetyIcon from "@images/icons/icon-ih-gradient-safety.svg"
import bottleIcon from "@images/icons/icon-ih-gradient-bottle.svg"
import jazzCareIcon from "@images/icons/icon-ih-gradient-jazzcare.svg"
import ButtonIH from "../components/buttonIH"

const IdiopathicHypersomnia = ({ location, data }) => {
  const ihSectionData = [
    {
      image: chartIcon,
      altText: "Clinical data icon",
      header: "Efficacy",
      textContent: (
        <>
          XYWAV was evaluated in adult patients with varying degrees of baseline
          disease severity and studied across multiple symptoms of IH.
          <sup>6,10</sup>
        </>
      ),
      cta: {
        link: "/idiopathic-hypersomnia/efficacy/",
        text: (
          <>
            EXPLORE <br tw="hidden md:block" />
            EFFICACY
          </>
        ),
      },
    },
    {
      image: safetyIcon,
      altText: "Safety check icon",
      header: "Safety Profile",
      textContent: (
        <>
          The safety and tolerability of XYWAV was established in a clinical
          trial of patients with IH aged 19&#8209;75 years.<sup>6</sup>
        </>
      ),
      cta: {
        link: "/idiopathic-hypersomnia/safety-and-tolerability/",
        text: (
          <>
            SEE SAFETY & <br tw="hidden md:block" />
            TOLERABILITY
          </>
        ),
      },
    },
    {
      image: bottleIcon,
      altText: "Medicine bottle icon",
      header: "Dosing & titration",
      textContent: (
        <>
          XYWAV offers individualized dosing so you can tailor treatment for
          each of your patients with IH.<sup>6</sup> In the clinical trial,
          participants took XYWAV alone or with stable doses of
          wake&#8209;promoting agents and/or stimulants based on their treatment
          regimen at enrollment.
          <sup>6,10</sup>
        </>
      ),
      cta: {
        link: "/idiopathic-hypersomnia/dosing/",
        text: (
          <>
            VIEW DOSING <br tw="hidden md:block" />& TITRATION
          </>
        ),
      },
    },
    {
      image: jazzCareIcon,
      altText: "Patient support icon",
      header: "Support & resources",
      textContent: (
        <>
          JazzCares<sup>®</sup> for XYWAV is committed to helping your patients
          right from the very start. Coverage and affordability assistance,
          personalized patient resources, and more are available.
        </>
      ),
      cta: {
        link: "/idiopathic-hypersomnia/resources",
        text: (
          <>
            FIND SUPPORT <br tw="hidden md:block" />& RESOURCES
          </>
        ),
      },
    },
  ]

  const referenceData = [
    <>
      American Academy of Sleep Medicine.{" "}
      <span tw="italic">International Classification of Sleep Disorders</span>.
      3rd ed, text revision. Darien, IL: American Academy of Sleep Medicine;
      2023.
    </>,
    <>
      Trotti LM, Ong JC, Plante DT, Murray CF, King R, Bliwise DL. Disease
      symptomatology and response to treatment in people with idiopathic
      hypersomnia: initial data from the Hypersomnia Foundation registry.{" "}
      <i>Sleep Med.</i> 2020;75:343&#8209;349.
    </>,
    <>
      Vernet C, Leu&#8209;Semenescu S, Buzare MA, Arnulf I. Subjective symptoms
      in idiopathic hypersomnia: beyond excessive sleepiness.{" "}
      <i>J Sleep Res.</i> 2010;19(4):525&#8209;534.
    </>,
    <>
      Arnulf I, Leu&#8209;Semenescu S, Dodet P. Precision medicine for
      idiopathic hypersomnia. <i>Sleep Med Clin.</i> 2019;14(3):333&#8209;350.
    </>,
    <>
      Trotti LM. Waking up is the hardest thing I do all day: sleep inertia and
      sleep drunkenness. <i>Sleep Med Rev.</i> 2017;35:76&#8209;84.
    </>,
    <>
      XYWAV<sup>®</sup> (calcium, magnesium, potassium, and sodium oxybates).
      Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
    <>
      FDA grants first of its kind indication for chronic sleep disorder
      treatment. News release. U.S. Food and Drug Administration; August 12,
      2021. Accessed March 18, 2024.
      https://www.fda.gov/news-events/press-announcements/fda-grants-first-its-kind-indication-chronic-sleep-disorder-treatment
    </>,
    <>
      Takenoshita S, Nishino S. Pharmacologic management of excessive daytime
      sleepiness. <i>Sleep Med Clin.</i> 2017;12(3):461-478.
    </>,
    <>
      Boulos MI, Murray BJ. Current evaluation and management of excessive
      daytime sleepiness. <i>Can J Neurol Sci.</i> 2010;37(2):167-176.
    </>,
    <>
      Dauvilliers Y, Arnulf I, Foldvary-Schaefer N, et al. Safety and efficacy
      of lower&#8209;sodium oxybate in adults with idiopathic hypersomnia: a
      phase 3, placebo&#8209;controlled, double&#8209;blind, randomised
      withdrawal study. <i>Lancet Neurol.</i> 2022;21(1):53&#8209;65.
    </>,
  ]

  const HeadingOnlyFDAApproved = () => {
    return (
      <>
        <p tw="uppercase text-base font-semibold mb-[22px] lg:mb-[22px]">
          {" "}
          The only FDA&#8209;approved treatment for adults with IH<sup>
            6,7
          </sup>{" "}
        </p>
        <h2 tw="mb-[22px] text-4xl lg:(mb-[22px] text-[40px])">
          {" "}
          Help prepare your patients with IH for multiple daily symptoms with
          nightly XYWAV<sup tw="lg:(text-[57%])">6</sup>*
        </h2>
      </>
    )
  }

  return (
    <Layout
      location={location}
      lowerVA={true}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200329 Rev0824
        </>
      }
    >
      <Seo
        title="FDA-Approved Treatment for Idiopathic Hypersomnia | XYWAV HCP"
        description="XYWAV (calcium, magnesium, potassium, and sodium oxybates) is a nightly medication studied across multiple Idiopathic Hypersomnia symptoms. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <HeroIH
        bgColour="black"
        vidSrc={"/videos/ih-hero.mp4"}
        vidPosterSrc={"/images/ih-hero.png"}
        altText=""
        mobileHeight="57"
        eyebrowPaddingTop="large"
      >
        <div tw="text-white mb-4 md:(max-w-[400px]) lg:(mt-[1.4rem])">
          <div
            css={[
              tw`font-medium leading-[120%] `,
              tw`text-[14px]`,
              tw`md:(text-[16px])`,
            ]}
          >
            For adult patients with <br />
            idiopathic hypersomnia (IH),
          </div>
          <h1
            css={[
              tw`my-1 uppercase font-stretchpro leading-[1.03] text-[30px] lg:text-[33px]`,
            ]}
            aria-label="The Endless Pull Back to Bed"
          >
            <div tw="mr-4 flex" aria-hidden="true">
              t<div tw="font-stretchprostretch scale-x-[0.9] mx-[-4px]">hh</div>
              e
            </div>
            <div tw="flex" aria-hidden="true">
              en
              <div tw="font-stretchprostretch scale-x-[0.9] mx-[-4px]">dd</div>
              less
            </div>
            <div tw="flex" aria-hidden="true">
              <div tw="flex">
                <div>p</div>
                <div tw="font-stretchprostretch pr-[47px] scale-x-[0.87] ml-[-3px] mr-[-12px] lg:mr-[-7px]">
                  uu
                </div>
                <div tw="ml-10 mr-4">ll</div>
              </div>
              <div tw="flex">
                <div>b</div>
                <div tw="font-stretchprostretch scale-x-[0.8] mx-[-11px]">
                  aa
                </div>
                <div>ck</div>
              </div>
            </div>
            <div tw="flex" aria-hidden="true">
              <div tw="flex">
                <div>t</div>
                <div tw="font-stretchprostretch mr-4 scale-x-[0.7] ml-[-12px] mr-[4px]">
                  oo
                </div>
              </div>
              <div tw="flex">
                <div>b</div>
                <div tw="font-stretchprostretch scale-x-[0.9] mx-[-4px]">
                  ee
                </div>
                <div>d</div>
              </div>
            </div>
          </h1>
          <div
            css={[
              tw`font-medium leading-[120%] `,
              tw`text-[14px]`,
              tw`md:(text-[16px])`,
            ]}
          >
            starts first thing in the morning.<sup>1-5</sup>
          </div>

          <p tw="mb-4 mt-[260px] text-[14px] md:text-[16px] font-black lg:mt-8">
            That’s why there’s XYWAV—
            <br />
            the treatment taken each night to help manage multiple daily
            symptoms of IH, such as excessive daytime sleepiness (EDS), sleep
            inertia, cognitive impairment, and prolonged sleep time.<sup>6</sup>
          </p>

          <ButtonIH
            link="/idiopathic-hypersomnia/diagnosis/"
            width={tw`w-[280px] lg:w-[340px]`}
          >
            IDENTIFY IH SYMPTOMS
          </ButtonIH>
          <p tw="text-[12px] font-bold mt-2.5 lg:mb-10">Actor portrayal.</p>
        </div>
      </HeroIH>

      <Section addedStyles={tw`bg-[#F5F1FA]`}>
        <Container addedStyles={tw`lg:(flex items-center)`}>
          <div tw="lg:hidden">
            {" "}
            <HeadingOnlyFDAApproved />{" "}
          </div>
          <div tw="max-w-[400px] mx-auto lg:w-[343px]">
            <GatsbyImage image={getImage(data.onlyFdaApproved)} alt="" />
          </div>
          <div tw="lg:(pl-[32px] w-[calc(100% - 343px)])">
            <div tw="hidden lg:block">
              {" "}
              <HeadingOnlyFDAApproved />{" "}
            </div>
            <p tw="mb-[22px] mt-[22px] lg:mt-0 mb-2 lg:mb-[22px]">
              {" "}
              Unlike stimulants and wake-promoting agents that are taken during
              the day, XYWAV is taken at night.<sup>6,8,9</sup>
            </p>

            <p tw="text-left text-[12px] leading-[16px] font-normal">
              {" "}
              *The exact way XYWAV works is unknown.<sup>6</sup>{" "}
            </p>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div tw="md:(grid grid-cols-2 gap-7) xl:grid-cols-4">
            {ihSectionData &&
              ihSectionData.map((item, index) => (
                <div key={index} tw="mb-8">
                  <BorderedContent
                    ih
                    thinBorder
                    extraCurved
                    addedStyles={tw`bg-transparent py-8 md:h-full`}
                  >
                    <div tw="md:(flex flex-col h-full justify-between)">
                      <div tw="mb-8">
                        <img src={item.image} alt={item.altText} tw="mx-auto" />
                        <h3
                          css={[
                            tw`text-center uppercase font-semibold my-[15px]`,
                          ]}
                        >
                          {item.header}
                        </h3>
                        <p tw="leading-[130%]">{item.textContent}</p>
                      </div>
                      <div tw="flex justify-center mt-4 md:mt-2">
                        <ButtonIH
                          width={tw`min-w-[280px] sm:(min-w-0 w-auto px-10) `}
                          link={item.cta.link}
                        >
                          {item.cta.text}
                        </ButtonIH>
                      </div>
                    </div>
                  </BorderedContent>
                </div>
              ))}
          </div>
        </Container>
      </Section>

      <References referenceData={referenceData} paddingPrimary={false} />
    </Layout>
  )
}

export default IdiopathicHypersomnia

IdiopathicHypersomnia.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IHQuery {
    onlyFdaApproved: file(
      relativePath: { eq: "IH-page/ih-only-fda-approved.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
